<template>
  <section v-show="user.information" class="addin">
    <div
      v-if="
        !user.loa_level_enabled.loa1_enabled &&
        !user.loa_level_enabled.loa2_enabled &&
        !user.loa_level_enabled.loa3_enabled
      "
    >
      <div class="info-header mt-2">{{ $t("LOA.TITLE") }}</div>

      <p class="mt-2 mb-4">{{ $t("LOA.LOA_DISABLED") }}</p>
    </div>

    <div
      v-if="
        user.loa_level_enabled.loa3_enabled ||
        user.loa_level_enabled.loa2_enabled ||
        user.loa_level_enabled.loa1_enabled
      "
    >
      <b-img
        fluid-grow
        class="mt-2 mb-2"
        src="img/signera-kryptera-skicka.png"
      ></b-img>

      <div v-if="finished">
        <InformationMessage type="information">
          <i class="fal fa-check"></i> {{ $t("COMPOSE.FINISHED_CLOSE") }}
        </InformationMessage>
        
        <InformationMessage type="warning">
          <i class="fal fa-exclamation"></i> {{ $t("COMPOSE.FINISHED_NOTE") }}
        </InformationMessage>
      </div>

      <div v-if="!finished">
        <div v-if="timeout">
          <p class="h200 bt-2">
            {{ $t("ERROR.COULD_NOT_SYNC") }}
          </p>

          <button
            v-on:click="startProcess"
            class="btn btn-block btn-fill btn-primary"
          >
            {{ $t("COMPOSE.RETRY") }}
          </button>
        </div>
        <div v-if="!timeout">
          <div v-if="loading == false" class="mt-2">
            <InformationMessage type="error" v-if="emptyParticipants">
              <i class="fal fa-exclamation-circle"></i>
              {{ $t("COMPOSE.ERROR.EMPTY_RECIPIENTS") }}
            </InformationMessage>

            <InformationMessage type="error" v-if="error">
              <i class="fal fa-exclamation-circle"></i>
              {{ $t("COMPOSE.ERROR.COULD_NOT_CREATE") }}
            </InformationMessage>

            <div class="settings" v-if="settings">
              <h6 class="mt-2">{{ $t("MESSAGES.LOA_LEVELS") }}</h6>

              <div class="mt-2" v-if="user.external_loa_levels">
                <div v-if="user.loa_level_enabled.loa3_enabled">
                  <b-form-radio v-model="loa_level" value="3" class="mt-2">
                    {{ loa3_text }} - LOA3
                    <LoaLevel :loa="3"></LoaLevel>
                  </b-form-radio>
                </div>
                <div v-if="user.loa_level_enabled.loa2_enabled">
                  <b-form-radio v-model="loa_level" value="2" class="mt-2">
                    {{ loa2_text }} - LOA2
                    <LoaLevel :loa="2"></LoaLevel>
                  </b-form-radio>
                </div>
                <div v-if="user.loa_level_enabled.loa1_enabled">
                  <b-form-radio v-model="loa_level" value="1" class="mt-2">
                    {{ loa1_text }} - LOA1
                    <LoaLevel :loa="1"></LoaLevel>
                  </b-form-radio>
                </div>
              </div>

              <div v-if="settings.reply">
                <h6 class="mt-2">{{ $t("RESPONSE") }}</h6>

                <b-form-checkbox
                  class="mt-2"
                  id="checkbox-1"
                  v-model="require_response"
                  name="require_response"
                  value="1"
                  unchecked-value="0"
                >
                  {{ $t("MESSAGES.REQUEST_RESPONSE") }}
                </b-form-checkbox>
              </div>

            </div>
            
            <InformationMessage class="mt-2" type="information">
              {{ $t("COMPOSE.BEFORE_FINISHED") }}
            </InformationMessage>
  

            <div class="mt-4">
              <b-button v-on:click="verifyEmails" variant="primary" block>
                {{ $t("COMPOSE.BUTTON") }}
              </b-button>
            </div>
          </div>
          <div v-if="loading == true" class="text-center">
            <div v-if="currentAction == 'FINISHED'">
              <InformationMessage type="information">
                <i class="fal fa-check"></i> {{ $t("COMPOSE.FINISHED") }}
              </InformationMessage>

              <InformationMessage type="warning">
                <i class="fal fa-exclamation"></i> {{ $t("COMPOSE.FINISHED_NOTE") }}
              </InformationMessage>

              <b-img
                center
                v-if="1 == 0"
                class="mt-2 mb-2"
                src="img/finished.png"
              ></b-img>
            </div>

            <div v-if="currentAction != 'FINISHED'">
              <InformationMessage type="information">
                <i class="fal fa-info-circle"></i> {{ actionMessage }}
              </InformationMessage>

              <b-progress :value="progress_bar"></b-progress>

              <b-img
                v-if="1 == 0"
                center
                class="loading50"
                src="img/loading.gif"
                fluid
              ></b-img>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import outlookMethods from "@/mixins/outlookMethods";
import localMethods from "@/mixins/localMethods";
import ewsMethods from "@/mixins/ewsMethods";
import restMethods from "@/mixins/restMethods";
export default {
  mixins: [outlookMethods, localMethods, ewsMethods, restMethods],
  components: {
    InformationMessage: () => import("@/components/Layout/InformationMessage"),
    LoaLevel: () => import("@/components/Compose/LoaLevel"),
  },
  data() {
    return {
      settings: null,
      message_uuid: "",
      show: false,
      error: false,
      require_response: 1,
      set_loa_level: 0,
      loa_level: 3,
      subject: "",
      progress_bar: 0,
      loading: false,
      addinClient: "",
      currentAction: "GET_INFORMATION",
      mailItemId: "",
      contentType: "",
      dialog: {},
      content: "",
      body: "",
      userProfile: this.$Office.context.mailbox.userProfile,
      participants: [],
      external_participants: [],
      registered: [],
      notregistered: [],
      to: [],
      cc: [],
      bcc: [],
      emails: [],
      external_text: "",
      attachments: [],
      checkTimeout: 10,
      currentTimout: 0,
      timer: "",
      timeout: false,
      verifyingDone: false,
      emptyParticipants: false,
      syncAction: "",
      actionMessage: "Loading ...",
      finished: 0,
      locale: this.$i18n.locale
    };
  },
  methods: {
    unlockSubject() {
      if (this.reply_subject == "") {
        this.subject = this.settings.subject;
      } else {
        this.subject = this.reply_subject;
      }
      this.settings.subject = "";
      this.settings.unlock_subject = 0;
    },
    unlockEmailBody() {
      this.settings.email_body = "";
      this.settings.unlock_email_body = 0;
    },
    async saveMessage() {
      let self = this;
      return new Promise((resolve) => {
        self.$Office.context.mailbox.item.saveAsync(function callback(result) {
          resolve(result.value);
        });
      });
    },
    async getBody() {
      let self = this;
      return new Promise((resolve) => {
        self.$Office.context.mailbox.item.body.getAsync(
          "text",
          { asyncContext: "This is passed to the callback" },
          function callback(result) {
            resolve(result.value);
          }
        );
      });
    },
    async getSubject() {
      let self = this;
      return new Promise((resolve) => {
        self.$Office.context.mailbox.item.subject.getAsync(function callback(
          result
        ) {
          resolve(result.value);
        });
      });
    },
    async setRecipientTo(recipientsList) {
      let recipients = [];
      for (let ix = 0; ix < recipientsList.length; ix++) {
        recipients.push(recipientsList[ix].EmailAddress.Address);
      }
      let self = this;
      return new Promise((resolve) => {
        self.$Office.context.mailbox.item.to.setAsync(
          recipients,
          function callback(asyncResult) {
            if (
              asyncResult.status === self.$Office.AsyncResultStatus.Succeeded
            ) {
              resolve(asyncResult.value);
            } else {
              resolve(null);
            }
          }
        );
      });
    },
    async setRecipientCc(recipientsList) {
      let recipients = [];
      for (let ix = 0; ix < recipientsList.length; ix++) {
        recipients.push(recipientsList[ix].EmailAddress.Address);
      }
      let self = this;
      return new Promise((resolve) => {
        self.$Office.context.mailbox.item.cc.setAsync(
          recipients,
          function callback(asyncResult) {
            if (
              asyncResult.status === self.$Office.AsyncResultStatus.Succeeded
            ) {
              resolve(asyncResult.value);
            } else {
              resolve(null);
            }
          }
        );
      });
    },
    async setRecipientBcc(recipientsList) {
      let recipients = [];
      for (let ix = 0; ix < recipientsList.length; ix++) {
        recipients.push(recipientsList[ix].EmailAddress.Address);
      }
      let self = this;
      return new Promise((resolve) => {
        self.$Office.context.mailbox.item.bcc.setAsync(
          recipients,
          function callback(asyncResult) {
            if (
              asyncResult.status === self.$Office.AsyncResultStatus.Succeeded
            ) {
              resolve(asyncResult.value);
            } else {
              resolve(null);
            }
          }
        );
      });
    },
    async getAttachmentContent(attachmentId) {
      let self = this;
      var options = {
        asyncContext: { currentItem: self.$Office.context.mailbox.item },
      };
      return new Promise((resolve) => {
        self.$Office.context.mailbox.item.getAttachmentContentAsync(
          attachmentId,
          options,
          function callback(result) {
            switch (result.value.format) {
              case self.$Office.MailboxEnums.AttachmentContentFormat.Base64:
                resolve(result.value);
                break;
              case self.$Office.MailboxEnums.AttachmentContentFormat.Eml:
                // Handle email item attachment.
                resolve("");
                break;
              case self.$Office.MailboxEnums.AttachmentContentFormat.ICalendar:
                // Handle .icalender attachment.
                resolve("");
                break;
              case self.$Office.MailboxEnums.AttachmentContentFormat.Url:
                // Handle cloud attachment.
                resolve("");
                break;
              default:
                resolve("");
                return "";
            }
          }
        );
      });
    },
    async getAttachmentsList() {
      let self = this;
      return new Promise((resolve) => {
        self.$Office.context.mailbox.item.getAttachmentsAsync(function callback(
          result
        ) {
          resolve(result.value);
        });
      });
    },
    async getAttachments() {
      this.attachments = [];
      let self = this;
      let attachments = await this.getAttachmentsList();
      if (attachments.length > 0) {
        for (let ix = 0; ix < attachments.length; ix++) {
          let attachmentInfo = attachments[ix];
          let contentBytes = await self.getAttachmentContent(attachmentInfo.id);
          self.attachments.push({
            name: attachmentInfo.name,
            type: attachmentInfo.contentType,
            content: contentBytes.content,
          });
        }
      }
    },
    async removeAttachment(attachmentId) {
      let self = this;
      var options = { asyncContext: null };
      return new Promise((resolve) => {
        console.log("trying to remove " + attachmentId);
        self.$Office.context.mailbox.item.removeAttachmentAsync(
          attachmentId,
          options,
          function callback(result) {
            console.log(result);
            resolve(result.status);
          }
        );
      });
    },
    async removeAttachments() {
      console.log("removeAttachments");
      this.attachments = [];
      let self = this;
      let attachments = await this.getAttachmentsList();
      if (attachments.length > 0) {
        for (let ix = 0; ix < attachments.length; ix++) {
          let attachmentInfo = attachments[ix];
          await self.removeAttachment(attachmentInfo.id);
        }
      }
    },
    async getTo() {
      let self = this;
      return new Promise((resolve) => {
        self.$Office.context.mailbox.item.to.getAsync(function (asyncResult) {
          if (asyncResult.status === self.$Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            resolve([]);
          }
        });
      });
    },
    async getCc() {
      let self = this;
      return new Promise((resolve) => {
        self.$Office.context.mailbox.item.cc.getAsync(function (asyncResult) {
          if (asyncResult.status === self.$Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            resolve([]);
          }
        });
      });
    },
    async getBcc() {
      let self = this;
      return new Promise((resolve) => {
        self.$Office.context.mailbox.item.bcc.getAsync(function (asyncResult) {
          if (asyncResult.status === self.$Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            resolve([]);
          }
        });
      });
    },
    async updateMessage() {
      let self = this;
      return new Promise((resolve) => {
        self.$Office.context.mailbox.item.body.setAsync(
          self.contentBody,
          { coercionType: self.$Office.CoercionType.Html },
          function (asyncResult) {
            if (
              asyncResult.status === self.$Office.AsyncResultStatus.Succeeded
            ) {
              resolve(asyncResult.value);
            } else {
              resolve([]);
            }
          }
        );
      });
    },
    async addAttachment(content) {
      let self = this;
      return new Promise((resolve) => {
        self.$Office.context.mailbox.item.addFileAttachmentFromBase64Async(
          content,
          "SEFOS.trv",
          { asyncContext: null },
          function (asyncResult) {
            console.log(asyncResult);
            if (
              asyncResult.status === self.$Office.AsyncResultStatus.Succeeded
            ) {
              resolve(asyncResult.value);
            } else {
              resolve([]);
            }
          }
        );
      });
    },
    getSettings() {
      let self = this;
      self.loading = true;
      this.$http
        .post(this.user.hostname + "/settings/get", {
          setting: "SefosMessage",
          organisation_uuid: this.user.information.organisation.organisation_uuid,
          functionbox_uuid: ""
        })
        .then(function (result) {
          self.settings = result.data;
          self.init();
          self.loading = false;
        })
        .catch(function (error) {
          self.loading = false;
          if(error.toString() == "Error: fail_4_4") return;
          self.$noty.error(self.$t("ERROR.COULD_NOT_FETCH"));
        });
    },
    async init() {
      if (this.settings.subject != "") {
        this.subject = this.settings.subject;
      }
      if (this.currentSignature != "") {
        this.content =
          "\n\n----------------------------\n" + this.currentSignature;
      }
      if (this.settings.body != "") {
        this.content = this.settings.body;
      }
      if (this.settings.email_body != "") {
        this.external_text = this.settings.email_body;
      }
      this.finished = 0;
      console.log(this.getRestUrl());
      this.$store.dispatch("logger/add", "Compose init");
      this.addinClient = this.$Office.context.mailbox.diagnostics.hostName;
      if (this.user.loa_level_enabled.loa1_enabled == 1) {
        this.loa_level = 1;
      }
      if (this.user.loa_level_enabled.loa2_enabled == 1) {
        this.loa_level = 2;
      }
      if (this.user.loa_level_enabled.loa3_enabled == 1) {
        this.loa_level = 3;
      }
    },
    async checkSync(mailItemId, iterations) {
      if (iterations <= 60) {
        iterations++;
        const parsed = parseInt(iterations * 0.8);
        this.progress_bar = 45 + parsed;
      } else {
        return false;
      }
      this.$store.dispatch("logger/add", "checkSync iterations " + iterations);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      let attachments = await this.outlookGetAttachments(mailItemId);
      if (this.attachments.length == 0) {
        return true;
      } else {
        if (attachments.length == 1) {
          if (attachments[0].Name == "SEFOS.trv") {
            return true;
          }
        } else {
          return await this.checkSync(mailItemId, iterations);
        }
      }
    },
    async createMessage() {
      try {
        this.error = false;
        this.finished = 0;
        this.loading = true;
        this.timeout = false;
        this.$store.dispatch("logger/add", "createMessage");
        this.subject = await this.getSubject();
        this.progress_bar = 2;
        this.body = await this.getBody();
        this.progress_bar = 5;
        await this.getAttachments();
        this.progress_bar = 10;
        let content = await this.secureMessage();
        this.progress_bar = 15;
        if (content != null) {
          await this.updateMessage();
          this.progress_bar = 20;
          await this.removeAttachments();
          this.progress_bar = 25;
          await this.addAttachment(content);
          this.progress_bar = 30;
          await this.setRecipientTo(this.to);
          await this.setRecipientCc(this.cc);
          await this.setRecipientBcc(this.bcc);
          this.progress_bar = 35;
          this.mailItemId = await this.saveMessage();
          this.finished = 1;
        } else {
          this.stopProcess();
        }
      } catch (error) {
        if(error.toString() == "Error: fail_4_4") return;
        if (error.response != undefined) {
          console.log(error.response);
          if (error.response.message != undefined) {
            this.$noty.error(error.response.message);
          }
        }
      }
    },
    async secureMessage() {
      this.$store.dispatch("logger/add", "secureMail");
      this.actionMessage = this.$t("COMPOSE.ACTION.SECURING_MESSAGE");
      try {
        this.participants = this.UniqueParticipants(this.participants);
        this.external_participants = this.UniqueParticipants(this.external_participants);        
        let result = await this.$http.post(
          this.user.hostname + "/message/binary/secure",
          {
            subject: this.subject,
            body: this.body,
            attachments: this.attachments,
            participants: this.participants,
            external_text: this.external_text,
            external_participants: this.external_participants,
            settings: {
              open_to_date: null,
              open_from_date: null,
              loa_level: parseInt(this.loa_level),
              require_response: parseInt(this.require_response),
            },
          }
        );
        this.$store.dispatch("logger/add", "secureMail done");
        this.currentAction = "SECURED_MAIL";
        return result.data.message;        
      } catch (err) {
        return null;
      }
    },
    async getMessageRecipients() {
      this.actionMessage = this.$t("COMPOSE.ACTION.GETTING_PARTICIPANTS");
      this.participants = [];
      this.emails = [];
      this.to = [];
      this.cc = [];
      this.bcc = [];
      let ToRecipients = await this.getTo();
      let CcRecipients = await this.getCc();
      let BccRecipients = await this.getBcc();
      for (let ix = 0; ix < ToRecipients.length; ix++) {
        let ToRecipient = ToRecipients[ix];
        this.to.push({
          EmailAddress: {
            Address: ToRecipient.emailAddress,
            Name: ToRecipient.displayName,
          },
        });
        this.emails.push(ToRecipient.emailAddress);
        this.participants.push({ email: ToRecipient.emailAddress });
      }
      for (let ix = 0; ix < CcRecipients.length; ix++) {
        let CcRecipient = CcRecipients[ix];
        this.cc.push({
          EmailAddress: {
            Address: CcRecipient.emailAddress,
            Name: CcRecipient.displayName,
          },
        });
        this.emails.push(CcRecipient.emailAddress);
        this.participants.push({ email: CcRecipient.emailAddress });
      }
      for (let ix = 0; ix < BccRecipients.length; ix++) {
        let BccRecipient = BccRecipients[ix];
        this.bcc.push({
          EmailAddress: {
            Address: BccRecipient.emailAddress,
            Name: BccRecipient.displayName,
          },
        });
        this.emails.push(BccRecipient.emailAddressddress);
        this.participants.push({ email: BccRecipient.emailAddress });
      }
      //FILTER OUT DUPLICATES
      this.emails = this.emails.filter(
        (element, i) => i === this.emails.indexOf(element)
      );
      //FILTER OUT DUPLICATES
      this.participants = this.participants.reduce((acc, current) => {
        if (!acc.some((item) => item.email === current.email))
          acc.push(current);
        return acc;
      }, []);
      this.currentAction = "FETCHED_PARTICIPANTS";
    },
    async stopProcess(){
      this.error = false;
      this.finished = 0;
      this.loading = false;
      this.timeout = false;
    },
    async verifyEmails() {
      let self = this;
      self.loading = true;
      await this.getMessageRecipients();
      if(this.emails.length == 0)
      {
        this.$noty.error(this.$t("ERROR.NO_RECIPIENT"));
        this.stopProcess();
      }
      this.actionMessage = this.$t("COMPOSE.ACTION.VERIFYING_EMAILS");
      if (this.emails.length != 0) {
        this.emptyParticipants = false;
        let result = await this.$http.post(
          this.user.hostname + "/sefos-message/verify/emails",
          {
            organisation_uuid: this.user.information.organisation.organisation_uuid,
            functionbox_uuid: '',
            emails: this.emails,
          }
        );
        this.registered = result.data.registered;
        this.notregistered = result.data.notregistered;
        if (this.registered != 0 && this.notregistered.length == 0) {
          this.verifyingDone = true;
          this.loading = false;
          this.createMessage();
        } else {
          this.verifyingDone = false;
          let userlist = JSON.stringify(result.data);
          this.$Office.context.ui.displayDialogAsync(
            self.addinUrl +
              "/index.html?action=composeDialog&email=" +
              this.user.information.email +
              "&loa_level=" +
              this.loa_level +
              "&userlist=" +
              userlist,
            { height: 80, width: 70, displayInIframe: true },
            self.viewCallback
          );
        }
        return true;
      } else {
        return false;
      }
    },
    async viewCallback(asyncResult) {
      let self = this;
      if (asyncResult.status == "failed") {
        //showNotification(asyncResult.error.message);
      } else {
        this.dialog = asyncResult.value;
        this.dialog.addEventHandler(
          this.$Office.EventType.DialogMessageReceived,
          self.viewMessageHandler
        );
        this.dialog.addEventHandler(
          this.$Office.EventType.DialogEventReceived,
          self.eventHandler
        );
      }
    },
    async removeEmail(email) {
      for (let ix = 0; ix < this.to.length; ix++) {
        if (email == this.to[ix].EmailAddress.Address) {
          this.to.splice(ix, 1);
        }
      }
      for (let ix = 0; ix < this.cc.length; ix++) {
        if (email == this.cc[ix].EmailAddress.Address) {
          this.cc.splice(ix, 1);
        }
      }
      for (let ix = 0; ix < this.bcc.length; ix++) {
        if (email == this.bcc[ix].EmailAddress.Address) {
          this.bcc.splice(ix, 1);
        }
      }
      for (let ix = 0; ix < this.emails.length; ix++) {
        if (email == this.emails[ix]) {
          this.emails.splice(ix, 1);
        }
      }
      for (let ix = 0; ix < this.participants.length; ix++) {
        if (email == this.participants[ix].email) {
          this.participants.splice(ix, 1);
        }
      }
    },
    async viewMessageHandler(arg) {
      let response = JSON.parse(arg.message);
      switch (response.message) {
        case "CLOSE":
          this.verifyingDone = false;
          this.loading = false;
          this.dialog.close();
          break;
        case "SEND":
          this.dialog.close();
          this.external_text = response.external_text;
          var removed_participants = JSON.parse(response.removed_participants);
          this.external_participants = JSON.parse(
            response.external_participants
          );
          for (let ix = 0; ix < this.external_participants.length; ix++) {
            let item = this.external_participants[ix];
            await this.removeEmail(item.email);
          }
          for (let ix = 0; ix < removed_participants.length; ix++) {
            let item = removed_participants[ix];
            await this.removeEmail(item.email);
          }
          if (
            this.registered.length == 0 &&
            this.external_participants.length == 0
          ) {
            this.verifyingDone = false;
            this.emptyParticipants = true;
            this.loading = false;
            this.timeout = false;
          }
          if (
            this.registered.length != 0 &&
            this.external_participants.length == 0
          ) {
            this.verifyingDone = true;
            this.createMessage();
          }
          if (
            this.registered.length != 0 &&
            this.external_participants.length != 0
          ) {
            this.verifyingDone = true;
            this.createMessage();
          }
          if (
            this.registered.length == 0 &&
            this.external_participants.length != 0
          ) {
            this.to.push({
              EmailAddress: {
                Address: this.userProfile.emailAddress,
                Name: this.userProfile.displayName,
              },
            });
            this.verifyingDone = true;
            this.createMessage();
          }
          break;
        default:
          break;
      }
    },
    async eventHandler(arg) {
      this.loading = false;
      switch (arg.error) {
        case 12002:
          break;
        case 12003:
          break;
        case 12006:
          this.verifyingDone = false;
          this.notregistered = [];
          this.registered = [];
          this.loading = false;
          this.timeout = false;
          this.currentTimout = this.checkTimeout;
          break;
        default:
          break;
      }
    },
  },
  computed: {
    sefos_url() {
      return this.user.hostname.replace("/server/rest", "");
    },
    contentBody() {
      return (
        '<table style="width: 100%; font-family: Segoe UI WestEuropean,Segoe UI,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif;-webkit-font-smoothing: antialiased;padding: 10px; width: 100%;"><tr><td style="text-align: center"><img style="max-height: 150px;  max-width: 300px;" src="' +
        this.addinUrl +
        '/img/mail.png" />​​</td></tr></table>'
      );
    },
    contentBody2() {
      if (this.registered.length == 0) {
        if (this.locale == "sv") {
          return 'Du har bara skickat till personer som inte finns registerade i SEFOS. <br />Det här meddelandet kommer gå till dig själv.<br /> Du kan trycka på "Skicka" för att skicka detta meddelande till dig själv för att kunna läsa meddelandet, se status osv.';
        } else {
          return 'You have sent only to people not registered in SEFOS. <br /> This message will only be sent to yourself. <br />You can press "Send" send this message to yourself to be able to view message and status etc.';
        }
      } else {
        if (this.locale == "sv") {
          return "Detta email har blivit krypterat av SEFOS.<br /><br />Du kan öppna detta meddelande med ditt Outlook Add-in.";
        } else {
          return "This mail has been encrypted by SEFOS.<br /><br />You can open this message with your Outlook Add-in.";
        }
      }
    },
    loa1_text: function () {
      if (this.user.loa_level_texts == undefined) {
        return this.$t("LOA.1");
      } else {
        if (this.user.loa_level_texts.loa1_text != "") {
          return this.user.loa_level_texts.loa1_text;
        } else {
          return this.$t("LOA.1");
        }
      }
    },
    loa2_text: function () {
      if (this.user.loa_level_texts == undefined) {
        return this.$t("LOA.2");
      } else {
        if (this.user.loa_level_texts.loa2_text != "") {
          return this.user.loa_level_texts.loa2_text;
        } else {
          return this.$t("LOA.2");
        }
      }
    },
    loa3_text: function () {
      if (this.user.loa_level_texts == undefined) {
        return this.$t("LOA.3");
      } else {
        if (this.user.loa_level_texts.loa3_text != "") {
          return this.user.loa_level_texts.loa3_text;
        } else {
          return this.$t("LOA.3");
        }
      }
    },
  },
  mounted() {
    console.log("Compose mounted");
    this.getSettings();
  },
};
</script>
<style></style>
